import type { UserGender } from '..';
import type { OptionsList } from '../../kernel';

export const useGenders = () => {
	return computed<OptionsList<UserGender>>(() => [
		{
			name: 'Мужской',
			code: 'M',
		},
		{
			name: 'Женский',
			code: 'F',
		},
	]);
};
